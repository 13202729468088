
<div class="library">
	<div class="docs">
		<app-library-item 
			[document]="document" 
			[isSuperAdmin]="isSuperAdmin"
			*ngFor="let document of documentsToShow" 
			(onEditClick)="editDocument($event)" 
			(onDeleteClick)="deleteDocument($event)">
		</app-library-item>
	</div>
	<div class="tags">
		<app-tags [(selectedTagIds)]="selectedTagIds" [allowEditing]="isSuperAdmin" (selectedTagIdsChange)="filterDocuments()" (onTagAdded)="tagAdded($event)" (onTagDeleted)="tagDeleted($event)" (onTagEdited)="tagEdited($event)"></app-tags>
	</div>
</div>


<ng-container *ngIf="showAddNewDocumentDialog && isSuperAdmin">
	<div class="mask" *ngIf="showAddNewDocumentDialog">
	  <div class="dialog">
		<h2>Add Document</h2>
		<div class="dialog-content">
		  <app-edit-document [document]="editingDocument" (onClose)="closeAddDocumentDialog()" (onSave)="onDocumentSaved($event)"></app-edit-document>
		</div>
	  </div>
	</div>
  </ng-container>

<ng-container *ngIf="showEditDocumentDialog">
	<div class="mask" *ngIf="showEditDocumentDialog">
		<div class="dialog">
			<h2>Edit Document</h2>
			<div class="dialog-content">
				<app-edit-document [document]="editingDocument" (onClose)="closeEditDocumentDialog()" (onSave)="onDocumentSaved($event)"></app-edit-document>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="documentsToReorder">
	<div class="mask" *ngIf="showReorderDocumentsDialog">
		<div class="dialog">
			<h2>Reorder Documents</h2>
			<div class="dialog-content">
				<p-orderList [value]="documentsToReorder" [dragdrop]="true">
					<ng-template let-doc pTemplate="item">
						<div class="card">{{doc.docTitle}}</div>
					</ng-template>
				</p-orderList>
			</div>
			<div class="actions">
				<div class="button neutral" (click)="closeReorderDocumentsDialog()">Cancel</div>
				<div class="button success" (click)="saveDocumentOrder()">Save</div>
			</div>
		</div>
	</div>
</ng-container>

<app-confirm-dialog
	[confirmHeader]="'Are you sure?'"
	[confirmBodyMsg]="'Are you sure you want to delete this document?'"
	[showDialog]="showDeleteDocumentConfirmation"
	[data]="deletingDocumentId"
	[buttons]="[{ text: 'Cancel', class: 'neutral' }, { text: 'Delete Document', class: 'danger' }]"
	(onClick)="onConfirmClick($event)"></app-confirm-dialog>
