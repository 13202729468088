<div class="one-morning" *ngIf="employer">
	<section class="hero">
		<h2>Better mornings for {{ employer.employerName }} start <em>here</em>.</h2>
	</section>
	<section class="well-being">
		<div class="copy fade-in">
			<div class="pre-headline">A New Morning for Employee Wellness</div>
			<div class="headline">Your well-being matters.</div>
			<div class="text">When the “good” in “good morning” feels hollow. When persevering seems impossible. When your to-do list is miles long, and getting help is yet another time-consuming task. One Morning has your back. Because life’s a struggle, but finding help shouldn’t be.</div>
		</div>
	</section>
	<section class="video">
		<video poster="/assets/images/video-poster.png" controls [interact]="{ engagementTypeID: 1, resource: 'Home Page Hero Video' }">
			<source [src]="env.homeVideoURL" type="video/mp4" />
		</video>
	</section>
	<section class="learn-more fade-in">
		<div class="img"></div>
		<div class="copy">
			<div class="headline">When you need help getting help.</div>
			<div class="text">Whatever life throws at us – anxiety, loss, hardship – help is much closer than we think. No matter your circumstances, there is free, confidential support through your Employee Assistance Program. Don’t wait until it’s too hard to ask for help.</div>
		</div>
	</section>
	<div class="sunrise">
		<section class="quote fade-in">
			<ng-container *ngIf="content[1] as data">
				<div class="quote-container">
					<p class="quote-content">“{{ data["Quote"] }}”</p>
					<p class="attribution">— {{ data["Quote Source"] }}</p>
				</div>
			</ng-container>
		</section>
		<section class="video-cards fade-in" *ngIf="videoCards?.length">
			<div class="copy">
				<div class="headline">Watch Real Stories</div>
				<div class="text">When people decide it's time to make a change, hope happens.</div>
			</div>
			<div class="video-card-holder">
				<p-carousel *ngIf="showCarousel" [value]="videoCards" [numVisible]="PAGE_SIZE" [circular]="false" [showIndicators]="true" [showNavigators]="false" (onPage)="onPageChange($event)" [ngClass]="{ 'first-page': carouselIndex <= 0, 'last-page': carouselIndex == videoCards.length - PAGE_SIZE || videoCards.length <= PAGE_SIZE }">
					<ng-template let-card pTemplate="item">
						<app-video-card [cardData]="card" (onPlay)="playVideo($event)"></app-video-card>
					</ng-template>
					<ng-template pTemplate="previousicon">
					</ng-template>
					<ng-template pTemplate="nexticon">
					</ng-template>
				</p-carousel>
			</div>
		</section>
	</div>
	<app-stressors></app-stressors>
	<section class="crisis-cards">
		<div class="crisis-content fade-in">
			<ng-container *ngIf="content[2] as data">
				<div class="info">
					<div class="headline">For Crisis Support</div>
					<div class="text">If you are having an emergency, get help right away.</div>
				</div>
				<app-crisis-card [icon]="'search'" [head]="'FindTreatment.GOV'" [body]="'FindTreatment.gov, the confidential and anonymous resource for persons seeking treatment for mental and substance use disrders in the United States and its territories.'" [link]="'https://findtreatment.gov'"></app-crisis-card>
				<app-crisis-card [icon]="'phone'" [head]="'Call the 988 Lifeline'" [body]="'If you or a loved one are in a crisis or having suicidal thoughts, call or text 988 for 24/7 confidential support.'" [link]="'https://988lifeline.org'"></app-crisis-card>
				<app-crisis-card [icon]="'heart'" [head]="'Get Local Help Now'" [body]="data['Text']" [link]="data['URL']"></app-crisis-card>
				<app-crisis-card [icon]="'cellphone'" [head]="'Text a Crisis Counselor'" [body]="'Text HOME to 741741 to connect with a trained volunteer Crisis Counselor.'" [link]="'https://www.crisistextline.org/'"></app-crisis-card>
			</ng-container>
		</div>
	</section>

	<section class="faqs fade-in">
		<div class="headline">FAQ</div>
		<ul class="faq-list">
			<li>
				<app-faq [question]="'How is One Morning different from my Employee Assistance Program (EAP)?'" [index]="1">
					<p>One Morning is here to complement your Employee Assistance Program (EAP) by making it easier for you to connect with the mental health resources you already have access to.</p>
					<p>Think of us as your friendly guide, here to help you find your way to the support you need. Although we’re not therapists, we can point you toward licensed counselors through your EAP. Remember, your EAP isn’t just for crisis situations. Talk through everyday solutions for everyday problems—24/7.</p>
					<p>Together with your organization, we believe in creating a healthier, more supportive workplace where everyone can thrive both in and out of work.</p>
				</app-faq>
			</li>
			<li>
				<app-faq [question]="'What does it cost?'" [index]="2">
					<p>Your EAP services are confidential and completely free to you, covered by your employer as part of your benefits package. This means you can access several sessions with a licensed counselor and other mental health resources without worrying about the cost. Curious about how many sessions you’re eligible for or what’s included? Check out our <a [routerLink]="['/learn-more']">Learn More page</a> for further details.</p>
				</app-faq>
			</li>
			<li>
				<app-faq [question]="'How do I access my EAP?'" [index]="3">
					<p>Getting the help you need is a phone call, text, or click away. You can visit the website, call or text their toll-free phone number, or download the app from your app store. If you’re not sure where to start, One Morning can help point you in the right direction; reach out to the contact info below.</p>
				</app-faq>
			</li>
			<li>
				<app-faq [question]="'I\'m in!  How can I be an advocate in my organization?'" [index]="4">
					<p>Advocacy starts with small, meaningful actions. Here are a few ways you can help make a difference:</p>
					<ul class="disc">
						<li><b>Share Your Story:</b> Personal stories can be powerful in encouraging others to seek help. If you’ve had a positive experience with One Morning, your EAP, or simply want to share your personal story, consider sharing it with your colleagues.  Want o share it with our and/or the One Morning team, we will send you a gift card.</li>
						<li><b>Be a Reminder:</b> If you hear someone talking about challenges at work or in life, remind them that resources are available. Sometimes, just knowing someone cares can make a huge difference.</li>
						<li><b>Open Up:</b> Don’t be afraid to talk openly about mental health. The more we normalize these conversations, the more we can break down the barriers that keep people from seeking support.</li>
					</ul>
				</app-faq>
			</li>
			<li>
				<app-faq [question]="'What if my EAP isn\'t responsive or not working for me or my family?'" [index]="5">
					<p><b>Help us, help you-  Email us at <a href="mailto:info@onemorning.com">info&#64;onemorning.com</a></b>  Tell us your experience so we tell your organization, we can't change what we don't know.  We care about you and your family.</p>
				</app-faq>
			</li>
		</ul>
		<div class="contact-info" *ngIf="content[5] as data">
			<ng-container *ngIf="data['Phone Number'] || data['Email']">
				<h5>Contact us for more info</h5>
				<ul>
					<li class="phone" *ngIf="data['Phone Number']">{{ data["Phone Number"] | phone }}</li>
					<li class="email" *ngIf="data['Email']">{{ data["Email"] }}</li>
				</ul>
			</ng-container>
		</div>
	</section>

	<section class="outro">
		<p class="fade-in">It gets <i>easier</i>, starting now.</p>
	</section>
</div>

<div class="video-player" *ngIf="activeVideo">
	<div class="video-content">
		<div class="close" (click)="closeVideo()"><span class="material-symbols-outlined">close</span></div>
		<h1 *ngIf="activeVideo.title">
			{{ activeVideo.title }}<span class="tag" *ngIf="activeVideo.tag">{{ activeVideo.tag }}</span>
		</h1>
		<iframe 
			*ngIf="isVimeo(activeVideo.videoFile)"
			[src]="activeVideo.videoFile | safe:'resourceUrl'"
			width="100%"
			frameborder="0"
			allow="autoplay; fullscreen; picture-in-picture"
			allowfullscreen>
		</iframe>
		<video *ngIf="!isVimeo(activeVideo.videoFile)" controls autoplay [interact]="{ engagementTypeID: 1, resource: activeVideo.title }">
			<source [src]="activeVideo.videoFile" type="video/mp4" />
		</video>
		<p *ngIf="activeVideo.cardContent">{{ activeVideo.cardContent }}</p>
	</div>
</div>
