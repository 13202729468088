import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

	@ViewChild('fileUpload') fileUpload: FileUpload;
	@Input() id: string;
	@Input() showPreview: boolean = true;
	@Input() allowedTypes: string = 'image/*';
	@Input() thumbnail: string = null;
	@Input() valid: boolean = true;
	@Input() fileUrl: string = null;
	@Output() idChange: EventEmitter<string> = new EventEmitter<string>();
	
	@Output() fileUploaded: EventEmitter<{ file: File, filePath: string, id: string }> = new EventEmitter<{ file: File, filePath: string, id: string }>();
	@Output() fileRemoved: EventEmitter<void> = new EventEmitter<void>();
	constructor(private dataService: DataService) { }

	uploadImage(e:any) {
		this.id = crypto.randomUUID(); //always assign a new id to upload
		this.idChange.emit(this.id);
		this.dataService.uploadFile(this.id, e.currentFiles[0]).subscribe((f) => {
			this.fileUploaded.emit({ file: e.currentFiles[0], filePath: f.filePath, id: this.id });
			if(this.allowedTypes === 'video/*') {
				this.generateVideoThumbnail(e.currentFiles[0]);
			}
		});
		this.thumbnail = null;

	}
	ngOnChanges(changes: SimpleChanges) {
		if(changes.thumbnail?.currentValue && this.allowedTypes === 'video/*') {
			this.getVideoThumbnail(changes.thumbnail.currentValue);
		}
	}
	removeFile() {
		this.fileUpload.clear();
		this.id = null;
		this.idChange.emit(this.id);
		this.thumbnail = null;
		this.fileRemoved.emit();
	}

	generateVideoThumbnail(file: File) {
		const videoElement = document.createElement('video');
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		
		videoElement.src = URL.createObjectURL(file);
	
		// Capture a frame from the video after it's loaded
		videoElement.addEventListener('loadeddata', () => {
		  canvas.width = videoElement.videoWidth;
		  canvas.height = videoElement.videoHeight;
	
		  // Seek to a frame and draw it on the canvas
		  videoElement.currentTime = 1; // 1 second into the video
		});
	
		// Draw the thumbnail image when the frame is ready
		videoElement.addEventListener('seeked', () => {
		  context?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
		  (this.fileUpload.files[0] as any).objectURL = canvas.toDataURL('image/png');
		});

		this.fileUrl = videoElement.src;
	  }

	getVideoThumbnail(url: string) {
		const videoElement = document.createElement('video');
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		
		videoElement.crossOrigin = 'anonymous';
		videoElement.src = url;
		// Capture a frame from the video after it's loaded
		videoElement.addEventListener('loadeddata', () => {
			canvas.width = videoElement.videoWidth;
			canvas.height = videoElement.videoHeight;
	  
			// Seek to a frame and draw it on the canvas
			videoElement.currentTime = 1; // 1 second into the video
		});
 		// Draw the thumbnail image when the frame is ready
		 videoElement.addEventListener('seeked', () => {
			context?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
			this.thumbnail = canvas.toDataURL('image/png');
		});
   
	}

}
