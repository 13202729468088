import { Component, Input } from '@angular/core';
import * as ScrollMagic from 'scrollmagic';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-stressors',
  templateUrl: './stressors.component.html',
  styleUrls: ['./stressors.component.scss']
})
export class StressorsComponent {

	constructor(private siteService: SiteService) { }
	ngAfterViewInit() {

		this.siteService.onResize.subscribe(size => {
			switch(size) {
				case 's': this.setupStressorItemsScroll(true); break;
				case 'm': this.setupStressorItemsScroll(true); break;
				case 'l': this.setupStressorItemsScroll(false); break;
				case 'xl': this.setupStressorItemsScroll(false); break;
			}
		});

	}

	setupStressorItemsScroll(isHorizontal: boolean) {
		const items = document.getElementById('stressor_items')
		let distance = 0;
		if (isHorizontal) {
			distance = items.scrollWidth;
		} else {
			distance = items.scrollHeight;
		}
		let controller = new ScrollMagic.Controller();
		let offset = document.getElementById('stressors').offsetHeight; //Math.floor(window.innerHeight * 0.5);
		let scene = new ScrollMagic.Scene({
			triggerElement: '#stressors',
			duration: distance,
			offset: offset,
			triggerHook: 1,
		}).setPin('#stressors').on("progress", (e:any) => {
			if (isHorizontal) {
				items.scrollTo(e.progress * distance, 0);
			} else {
				items.scrollTo(0, e.progress * distance);
			}
		}).addTo(controller);
	}



}
