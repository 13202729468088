<div class="one-morning" *ngIf="employer">
	<section class="hero">
		<div class="hero-img"><h2>You are so much <em>more</em> than your job.</h2></div>
	</section>

	<section class="new-light" *ngIf="content[3] as data">
		<div class="col fade-in">
			<div class="headline">Seeing mental health in a new light. </div>
			<p class="text">Our mental health is with us wherever we are.  Why pretend it doesn't affect us?  By facing it head-on, we can embrace the possibility of better.</p>
			<p class="text">With the EAP included in your employee benefits package, get the support you need at zero cost.</p>
			<p class="text">Talk it out.  Type it out.  Text it out.  Connect with a counselor in the way that works for you.  Confidential support is available 24/7 for you and your entire household &ndash; ready whenever you need it.</p>
			<p class="text url-code">
				<a class="cta" [href]="data['URL']" target="_blank">Visit Your EAP</a>
				<span><b>Enter group code:</b>&nbsp;{{data['Group Code']}}</span>
			</p>
		</div>
		<div class="col fade-in">
			<ul class="access">
				<li *ngIf="data['Free Visits']">
					<span>{{data['Free Visits']}}</span>
					<span>Free one-on-one counseling sessions per person per year.</span>
				</li>
				<li *ngIf="data['Access'] && data['Toll Free Number']">
					<span>{{data['Access']}}</span>
					<span>Live, toll-free phone access to licensed, experienced counselors by calling <i class="nowrap">{{data['Toll Free Number'] | phone}}</i></span>
				</li>
				<li *ngIf="data['Text Phone Number']">
					<span><img src="/assets/images/mobile-phone.svg"></span>
					<span>Text a mental health coach for emotional support.  Message "Hi" to {{data['Text Phone Number'] | phone}}</span>
				</li>
			</ul>
		</div>
	</section>

	<section class="video">
		<video poster="/assets/images/learn-more-video-poster.png" controls [interact]="{engagementTypeID: 1, resource: 'Learn More Hero Video'}">
			<source [src]="env.learnMoreVideoURL" type="video/mp4">
		</video>
	</section>

	<section class="topics fade-in" *ngIf="topicCardsToShow">
		<div class="pre-headline">Support for life's challenges</div>
		<div class="headline">Just because you can handle it,<br/>doesn't mean you should.</div>
		<div class="topic-cards">
			<div *ngFor="let card of topicCardsToShow"><app-topic-card [cardData]="card"></app-topic-card></div>
		</div>
		<div class="load-more-holder">
			<div class="load more" (click)="loadMore()" *ngIf="topicCardsToShow?.length < topicCards?.length">Load More</div>
			<div class="load less" (click)="showLess()" *ngIf="topicCardsToShow?.length == topicCards?.length">Show Less</div>
		</div>
	</section>

	<ng-container *ngIf="content[4] as data">
	<section class="download fade-in" *ngIf="data['Show This Section'] == 'true'">
		<div class="col qr-holder">
			<div class="phone-holder">
				<div class="eap-logo"><img [src]="data['EAP App Logo']"></div>
				<div class="eap-name">{{data['EAP App Name']}}</div>
			</div>
			<div class="get-app">
				<div class="qr-code desktop">
					<img [src]="data['QR Code']">
				</div>
				<div class="app-links">
					<img class="desktop" src="/assets/images/download-arrow.png">
					<p class="desktop">Scan to download</p>
					<ul>
						<li *ngIf="data['Apple Store Link']"><a [href]="data['Apple Store Link']" target="_blank" [interact]="{engagementTypeID: 4, resource: 'Apple Store Link'}"><img src="/assets/images/App-Store_x2.png"></a></li>
						<li *ngIf="data['Google Play Link']"><a [href]="data['Google Play Link']" target="_blank" [interact]="{engagementTypeID: 4, resource: 'Google Play Link'}"><img src="/assets/images/Google-Play_x2.png"></a></li>
					</ul>
				</div>
			</div>
		</div>
		<div class="col">
			<p class="headline">Get support anytime.</p>
			<p class="text no-scan-qr-text">When you can't talk to anyone, you can talk to us.  Download the app from your app store to access expert help and resources whenever you or someone in your houshold needs it.</p>
			<p class="text scan-qr-text">When you can't talk to anyone, you can talk to us.  Scan the QR code or download the app from your app store to access expert help and resources whenever you or someone in your houshold needs it.</p>
		</div>
	</section>
	</ng-container>
	<section class="assessment fade-in">
		<div class="copy">
			<div class="headline">Check in on your mental health.</div>
			<div class="text">Not sure where to start? These national mental health assessments provide a quick snapshot of your well-being, helping you recognize patterns and opening the door to deeper conversations with a trusted individual or health provider.</div>
			<div class="actions">
				<a class="cta" href="https://screening.mhanational.org/screening-tools/" target="_blank" [interact]="{engagementTypeID: 4, resource: 'Take An Assessment'}">Take an Assessment</a>
			</div>
		</div>
		<div class="img"></div>
	</section>

	<section class="crisis-cards" *ngIf="false">
		<div class="crisis-content fade-in">
			<div class="info">
				<div class="headline">For crisis support</div>
				<div class="text">If you are having an emergency,<br/>get help right away.</div>
			</div>
			<app-crisis-card [icon]="'phone'" [head]="'Call 988 for the Suicide and Crisis Lifeline'" [body]="'If you or a loved one are in a crisis or having suicidal thoughts, call or text 988 for immediate, confidential support, available 24/7.'"></app-crisis-card>
			<app-crisis-card [icon]="'heart'" [head]="'Get Local Help Now'" [body]="'Access local resources in Dayton, OH for comprehensive support.'"></app-crisis-card>
		</div>
	</section>

	<section class="outro">
		<p class="fade-in">Find <em>your</em> way back to you.       </p>
	</section>
</div>