<section id="stressors">
	<div class="copy">
		<div class="headline">Life's stressors <em>don't</em> discriminate.</div>
		<div class="text">Whatever you're going through, the burden doesn't have to rest on your shoulders alone.  Your EAP is here for you, with experts who know exactly how to help.</div>
		<div class="actions"><a class="cta" [routerLink]="['/learn-more']" [interact]="{engagementTypeID: 4, resource: 'Get Support Now'}">Get Support Now</a></div>
	</div>
	<div class="items" id="stressor_items">
		<div class="item item-1 bottom left"><div class="glow"><span>"Will I ever be able to start a family?"</span></div></div>
		<div class="item item-4 bottom left"><div class="glow"><span>"How much longer can I go on like this?"</span></div></div>
		<div class="item item-3 bottom right"><div class="glow"><span>"Will they take my car?"</span></div></div>
		<div class="item item-2 bottom center"><div class="glow"><span>"Why can't I stop after one drink?"</span></div></div>
		<div class="item item-5 bottom left"><div class="glow"><span>"How can I support us both?"</span></div></div>
	</div>	
</section>
