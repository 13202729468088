import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { HeaderButton } from "../models/header-button";
import { Employer } from "../models/employer";
import { BreakpointObserver } from "@angular/cdk/layout";


@Injectable({
	providedIn: 'root'
})
export class SiteService {
	public onPageTitleSet: ReplaySubject<string> = new ReplaySubject();
	public onShowLoader: EventEmitter<string> = new EventEmitter();
	public onHideLoader: EventEmitter<string> = new EventEmitter();
	public onHeaderButtonsSet: EventEmitter<Array<HeaderButton>> = new EventEmitter();
	public onEmployerSet: BehaviorSubject<Employer> = new BehaviorSubject(null);
	public onBackButtonSet: BehaviorSubject<{link: string, title?: string}> = new BehaviorSubject(null);
	public onShowDisclaimers: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public onPageInitDone: Subject<boolean> = new Subject();

	public onMessageSet: EventEmitter<{detail: string, severity: 'info' | 'warn' | 'success' | 'error', life: number}> = new EventEmitter();

	public onResize: ReplaySubject<'s' | 'm' | 'l' | 'xl'> = new ReplaySubject();
	private _currentSize: 's' | 'm' | 'l' | 'xl' = 'xl';

	constructor(private breakpointObserver: BreakpointObserver) { 
		this.breakpointObserver.observe(['(max-width: 660px)', '(max-width: 800px)', '(max-width: 1080px)']).subscribe(result => {
			this._currentSize = 'xl';
			if((result.breakpoints['(max-width: 660px)'])) { this._currentSize = 's'; }
			else if (result.breakpoints['(max-width: 800px)']) { this._currentSize = 'm'; }
			else if (result.breakpoints['(max-width: 1080px)']) { this._currentSize = 'l'; }
			this.onResize.next(this._currentSize );
		});
	}
	showLoader(id: string): void {
		this.onShowLoader.emit(id);
	}
	hideLoader(id: string): void {
		this.onHideLoader.emit(id);
	}
	setMessage(message: string, severity: 'info' | 'warn' | 'success' | 'error' = 'success', life: number = 3000) {
		this.onMessageSet.emit({ detail: message, severity, life })
	}

	set pageTitle(title: string) {
		this.onPageTitleSet.next(title);
	}

	set headerButtons(buttons: Array<HeaderButton>) {
		this.onHeaderButtonsSet.emit(buttons);
	}

	set backButton(data: {link: string, title?: string}) {
		this.onBackButtonSet.next(data);
	}

	setEmployer(employer: Employer): void {
		this.onEmployerSet.next(employer);
	}

	set showDisclaimers(show: boolean) {
		this.onShowDisclaimers.next(show);
	}

	pageInitDone() {
		this.onPageInitDone.next(true);
	}

}