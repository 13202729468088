<div class="tabs" [ngSwitch]="activeTab" *ngIf="employer">
	<div class="tab" *ngSwitchCase="'configure'" [formGroup]="form">
		<div class="grid">
			<div class="field">
				<div class="label">Employer Name</div>
				<div><input pInputText [formControlName]="'employerName'"></div>
			</div>
			<div class="field">
				<div class="label">Web Site Path (yourpathhere.onemorning.com)</div>
				<div><input pInputText [formControlName]="'path'"></div>
			</div>
			<div class="field">
				<div class="label">Logo</div>
				<div class="company-logo">
					<img [src]="employer.logo" [alt]="employer.employerName + ' Logo'" *ngIf="employer.logo" />
				</div>
				<div class="actions">
					<app-file-upload [(id)]="employer.employerLogoUploadID" (fileUploaded)="onLogoUpload($event)" [showPreview]="false"></app-file-upload>
					<div class="button danger" (click)="deleteLogo()">Delete Logo</div>
				</div>
			</div>
			<div class="field" *ngIf="employer.qrCode">
				<div class="label">QR Code</div>
				<div class="qr-code">
					<img [src]="employer.qrCode" [alt]="employer.employerName + ' QR Code'" />
					<div (click)="copyImage()" class="action"><span class="material-symbols-outlined" title="Copy QR Code">content_copy</span></div>
					<div class="action"><a [href]="'api/employers/'+employer.employerID+'/qr'" download="{{employer.employerName}}_qr_code.png"><span class="material-symbols-outlined" title="Download QR Code">download</span></a></div>

				</div>
			</div>
		</div>

		
	</div>
	<div class="tab" *ngSwitchCase="'customizations'">
		<ng-container *ngFor="let category of employer.employerContent.contentCategories">
			<div class="category">
				<h3>{{category.contentCategoryDesc}}</h3>
				<div class="content">
					<div class="fields">
						<div class="field" *ngFor="let content of category.content" [ngClass]="{'has-error': !!content.error}">
							<div class="label">
								<span>{{content.contentDesc}}</span>
								<div class="reset-button button neutral" *ngIf="content.textContent != content.defaultTextContent" (click)="resetText(content)">Reset to Default</div>
							</div>
							<ng-container [ngSwitch]="content.contentTypeID">
								<div class="value" *ngSwitchCase="1">
									<textarea pInputTextarea [(ngModel)]="content.textContent" [autoResize]="true" (focus)="editingField = content.contentID" (blur)="editingField = ''" [ngClass]="{ editing: contentField == content.contentID}"></textarea>
								</div>
								<div class="value" *ngSwitchCase="2">
									<input pInputText [(ngModel)]="content.textContent" (focus)="editingField = content.contentID" (blur)="editingField = ''" [ngClass]="{ editing: contentField == content.contentID}">
								</div>
								<div class="value" *ngSwitchCase="5">
									 <div class="file-upload-container">
										<app-file-upload [(id)]="content.fileUploadID" [thumbnail]="content.fileContent" (fileUploaded)="onFileUpload($event, content)" (fileRemoved)="onContentFileRemoved(content)"></app-file-upload>
									 </div>
								</div>
								<div class="value" *ngSwitchCase="6">
									<p-inputSwitch [(ngModel)]="content.boolContent" (onChange)="setCheckboxValue($event, content)" ></p-inputSwitch>
								</div>
							</ng-container>
							<div class="error" *ngIf="content.error">{{content.error}}</div>
						</div>
					</div>
					<div class="preview">
						<img [src]="category.helperImage" alt="" />
						<ng-container *ngFor="let content of category.content">
							<div class="box" *ngIf="content.previewTop" [ngStyle]="content.style" [ngClass]="{ editing: editingField == content.contentID}" (click)="setContentField(content.contentID)">{{content.textContent}}</div>
						</ng-container>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="tab" *ngSwitchCase="'video-cards'">
		<div class="video-cards">
			<ng-container *ngFor="let card of videoCards">
				<app-admin-card [cardData]="card" [canDelete]="true" [canEdit]="!!card.employerID" (onAddRemoveCard)="setCardInclusion($event)" (onEditCard)="editCard(card)"></app-admin-card>
			</ng-container>
		</div>
		<div *ngIf="videoCards.length == 0">
			<h3>No Video Cards have been set up for this employer.  Click the "Add New" button above to get started.</h3>
		</div>
	</div>
	<div class="tab" *ngSwitchCase="'topic-cards'">
		<div class="topic-cards">
			<ng-container *ngFor="let card of topicCards">
				<app-admin-card [cardData]="card" [canDelete]="true" [canEdit]="!!card.employerID" (onAddRemoveCard)="setCardInclusion($event)" (onEditCard)="editCard(card)"></app-admin-card>
			</ng-container>
		</div>
		<div *ngIf="topicCards.length == 0">
			<h3>No Topic Cards have been set up for this employer.  Click the "Add New" button above to get started.</h3>
		</div>
	</div>
	<div class="tab" *ngSwitchCase="'library'">
		<div class="library-tab">
			<div class="docs">
				<p *ngIf="allDocuments.length == 0">No documents to show</p>
				<app-library-item 
					[document]="document" 
					*ngFor="let document of documentsToShow" 
					[buttons]="isSuperAdmin ? (document.employerID ? ['edit', 'delete'] : ['remove']) : []" 
					(onEditClick)="editDocument($event)" 
					(onDeleteClick)="deleteDocument($event)" 
					(onRemoveClick)="removeDocument($event)">
				</app-library-item>
			</div>
			<div class="tags">
				<app-tags [(selectedTagIds)]="selectedTagIds" (selectedTagIdsChange)="filterDocuments()"></app-tags>
			</div>
		</div>
	</div>
	<div class="tab" *ngSwitchCase="'census-data'">
		<div class="census-data">
			<h2>Upload Census Data File</h2>
			<p>Census Data File must be a .csv file, with the following fields, in this order:</p>
			<ul>
				<li>First Name</li>
				<li>Last Name</li>
				<li>Email Address</li>
				<li>Phone Number</li>
				<li>Employee Category</li>
			</ul>
			<div class="upload-field">
				<p-fileUpload 
					mode="basic" 
					accept=".csv" 
					(onSelect)="uploadCensusData($event)" 
					#censusUploadField
				></p-fileUpload>			
				<div class="button neutral" (click)="clearCensusFile()" *ngIf="censusUploadField.files.length > 0">Clear</div>
			</div>
		</div>
	</div>
</div>



<ng-container *ngIf="employer">
<div class="mask" *ngIf="showAddCardDialog">
	<div class="dialog">
		<h2>Add Card</h2>
		<div class="dialog-content">
			<app-add-card [cardContainerID]="addingCardContainerID" [employerID]="employer.employerID" [existingCards]="existingCards" (onClose)="closeAddCardDialog()"></app-add-card>
		</div>

	</div>
</div>
</ng-container>

<ng-container *ngIf="editingCard">
	<div class="mask" *ngIf="showEditCardDialog">
		<div class="dialog">
			<h2>Edit Card</h2>
			<div class="dialog-content">
				<app-edit-card [cardData]="editingCard" (onClose)="closeEditCardDialog()"></app-edit-card>
			</div>
	
		</div>
	</div>
</ng-container>
	
<ng-container *ngIf="cardsToReorder">
	<div class="mask" *ngIf="showReorderDialog">
		<div class="dialog">
			<h2>Reorder Cards</h2>
			<div class="dialog-content">
				<p-orderList [value]="cardsToReorder" [dragdrop]="true">
					<ng-template let-card pTemplate="item">
						<div class="card">{{card.title}}</div>
					</ng-template>
				</p-orderList>
			</div>
			<div class="actions">
				<div class="button neutral" (click)="closeReorderDialog()">Cancel</div>
				<div class="button success" (click)="saveCardOrder()">Save</div>
			</div>
		</div>
	</div>
</ng-container>	

<ng-container *ngIf="documentsToReorder">
	<div class="mask" *ngIf="showReorderDocumentsDialog">
		<div class="dialog">
			<h2>Reorder Documents</h2>
			<div class="dialog-content">
				<p-orderList [value]="documentsToReorder" [dragdrop]="true">
					<ng-template let-doc pTemplate="item">
						<div class="card">{{doc.docTitle}}</div>
					</ng-template>
				</p-orderList>
			</div>
			<div class="actions">
				<div class="button neutral" (click)="closeReorderDocumentsDialog()">Cancel</div>
				<div class="button success" (click)="saveDocumentOrder()">Save</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="showAddNewDocumentDialog">
	<div class="mask" *ngIf="showAddNewDocumentDialog">
		<div class="dialog">
			<h2>Add Document</h2>
			<div class="dialog-content">
				<app-add-document (onClose)="closeAddDocumentDialog()" (onSave)="selectedTagIds = [];refreshDocs()" [employerID]="employer.employerID" [existingDocuments]="allDocuments"></app-add-document>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="showEditDocumentDialog"></ng-container>
	<div class="mask" *ngIf="showEditDocumentDialog">
		<div class="dialog">
			<h2>Edit Document</h2>
			<div class="dialog-content">
				<app-edit-document [document]="editingDocument" (onClose)="closeEditDocumentDialog()" (onSave)="onDocumentSaved($event)"></app-edit-document>
			</div>
		</div>
	</div>

<app-confirm-dialog
	[confirmHeader]="'Are you sure?'"
	[confirmBodyMsg]="'Are you sure you want to delete this employer?'"
	[showDialog]="showDeleteEmployerConfirmation"
	[buttons]="[{ text: 'Cancel', class: 'neutral' }, { text: 'Delete', class: 'danger' }]"
	(onClick)="onConfirmClick($event)"></app-confirm-dialog>

<app-confirm-dialog
	[confirmHeader]="'Delete Permanently?'"
	[confirmBodyMsg]="'Do you want to temporarily hide this card from this employer\'s site, or delete it permanently?'"
	[showDialog]="showDeleteCardConfirmation"
	[data]="deletingCardId"
	[buttons]="[{ text: 'Hide Temporarily', class: 'danger' }, { text: 'Delete Permanently', class: 'danger' }, { text: 'Cancel', class: 'neutral' }, ]"
	(onClick)="onConfirmClick($event)"></app-confirm-dialog>

<app-confirm-dialog
	[confirmHeader]="'Save Changes First?'"
	[confirmBodyMsg]="'You have pending changes.  Do you want to save them before previewing?'"
	[showDialog]="showSaveChangesConfirmation"
	[buttons]="[{ text: 'Save Changes', class: 'success' }, { text: 'Preview without Saving', class: 'neutral' }]"
	(onClick)="onConfirmClick($event)"></app-confirm-dialog>

<app-confirm-dialog
	[confirmHeader]="'Are you sure?'"
	[confirmBodyMsg]="'Are you sure you want to delete this document permanently?'"
	[showDialog]="showDeleteDocumentConfirmation"
	[data]="deletingDocumentId"
	[buttons]="[{ text: 'Cancel', class: 'neutral' }, { text: 'Delete Document', class: 'danger' }]"
	(onClick)="onConfirmClick($event)"></app-confirm-dialog>

<app-confirm-dialog
	[confirmHeader]="'Are you sure?'"
	[confirmBodyMsg]="'Are you sure you want to remove this document?'"
	[showDialog]="showRemoveDocumentConfirmation"
	[data]="deletingDocumentId"
	[buttons]="[{ text: 'Cancel', class: 'neutral' }, { text: 'Remove Document', class: 'danger' }]"
	(onClick)="onConfirmClick($event)"></app-confirm-dialog>

	<app-confirm-dialog
	[confirmHeader]="'Confirm URL update'"
	[confirmBodyMsg]="'Changing the path will rebuild the employer QR Code.  QR codes than have already been distributed will no longer work properly.  Are you sure you want to change the path?'"
	[showDialog]="showPathUpdateConfirmation"
	[data]="null"
	[buttons]="[{ text: 'Cancel', class: 'neutral' }, { text: 'Continue Saving', class: 'danger' }]"
	(onClick)="onConfirmClick($event)"></app-confirm-dialog>

