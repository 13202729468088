export class Content {
	public contentID: string;
	public contentDesc: string;
	public contentDescLong: string;
	public contentTypeID: number;
	public fileContent: string;
	public fileUploadID: string;
	public textContent: string;
	public resource: string;
	public defaultFileContent: string;
	public defaultTextContent: string;
	public defaultResource: string;
	public sortOrder: number;
	public previewTop: number;
	public previewLeft: number;
	public previewWidth: number;
	public previewHeight: number;
	public previewStyleAttribs: string;

	public boolContent: boolean;
	public error?: string;

	constructor(o?: any) {
		Object.assign(this, o);
		switch(this.textContent) {
			case "true": this.boolContent = true; break;
			case "false": this.boolContent = false; break;
			default: this.boolContent = null;
		}
	}

	public get style() {
		let style = this.previewStyleAttribs ? JSON.parse(this.previewStyleAttribs.split("'").join('"')) : {};
		style['top'] = this.previewTop + 'px';
		style['left'] = this.previewLeft + 'px';
		style['width'] = this.previewWidth + 'px';
		style['height'] = this.previewHeight + 'px';
		return style;
	}

	public isMatch(compareTo: Content): boolean {
		if((this.textContent ?? "") !== (compareTo.textContent ?? "")) { //convert null to empty string since it's all the same
			return false;
		}
		if(this.fileContent !== compareTo.fileContent) {
			return false;
		}
		return true;
	}
}

export class ContentCategory {
	public contentCategoryID: number;
	public contentCategoryDesc: string;
	public helperImage: string;
	public content: Array<Content>;

	constructor(o?: any) {
		if(o.content) {
			o.content = o.content.map((c:any) => new Content(c));
		}
		if(o.backgroundImage) {
			o.backgroundImage = '/assets/images/content/' + o.backgroundImage;
		}
		Object.assign(this, o);
	}

	public isMatch(compareTo: ContentCategory): boolean {
		if(this.content.length !== compareTo.content.length) {
			return false;
		}
		for(let i = 0; i < this.content.length; i++) {
			if(!this.content[i].isMatch(compareTo.content[i])) {
				return false;
			}
		}
		return true;
	}
}