import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Employer } from 'src/app/models/employer';
import { SessionService } from 'src/app/services/session.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-user-template',
  templateUrl: './user-template.component.html',
  styleUrls: ['./user-template.component.scss']
})
export class UserTemplateComponent {

	public activeLink: string = 'home';
	public path: string;
	public menuOpen: boolean = false;
	public logo: string = '';
	public employer: Employer = null;
	public showDisclaimers: boolean = false;

	constructor(private route: ActivatedRoute, private router: Router, private siteService: SiteService, private sessionService: SessionService) { }

	ngOnInit(): void {

		this.route.firstChild.data.subscribe(d => {
			this.activeLink = d?.activeLink;
		})
		this.route.firstChild.paramMap.subscribe(p => {
			this.path = p.get('path')
		});
		this.router.events.subscribe(e => {
			this.menuOpen = false;
			if(e.type == 11) {
				this.activeLink = e.snapshot.firstChild.data?.activeLink;
			}
		});
		this.siteService.onEmployerSet.subscribe((e:Employer) => {
			this.employer = e;
			if(e) {
				this.sessionService.employerID = e.employerID;
				this.logo = '"' + e.logo + '"';
			}
		});
		this.siteService.onShowDisclaimers.subscribe((s:boolean) => {
			this.showDisclaimers = s;
		});

		this.siteService.onPageInitDone.subscribe(() => {
		 	this.setUpObserver()
		});

	}

	setUpObserver() {
		const fadeins = document.querySelectorAll('.fade-in');
		if(!fadeins || fadeins.length == 0) { 
			setTimeout(this.setUpObserver, 500);
			return; 
		}
		const options: any = { root: null, threshold: 0.35 };
		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if(!entry.isIntersecting) { return; }
				entry.target.classList.add('appear');
				observer.unobserve(entry.target);
			});
		}, options);

		fadeins.forEach((entry:any) => {
			observer.observe(entry);
		});

		
	}
}
