import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { ContentCard } from 'src/app/models/content-card';
import { Employer } from 'src/app/models/employer';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.scss'],
})
export class LearnMoreComponent {
	public employer: Employer;
	public path: string;
	public content: any = {};
	public topicCards: Array<ContentCard> = [];
	public topicCardsToShow: Array<ContentCard> = [];
	public topicCardGroupsVisible: number = 1;
	public topicCardsPerGroup: number = 4;
	public env = environment;

	constructor(private dataService: DataService, private route: ActivatedRoute, private siteService: SiteService) {
		this.route.parent?.parent?.data.subscribe(data => {
			this.path = data['path'] || window.location.hostname.split('.')[0];
		});
	}

	ngOnInit(): void {
		this.siteService.onResize.subscribe(size => {
			switch(size) {
				case 's': this.topicCardsPerGroup = 2; break;
				case 'm': this.topicCardsPerGroup = 2; break;
				case 'l': this.topicCardsPerGroup = 3; break;
				case 'xl': this.topicCardsPerGroup = 4; break;
			}
			this.layoutTopicCards();
		});
		this.siteService.showDisclaimers = true;
		this.route.paramMap.pipe(
			tap(p => {
				this.path = p.get('path') || window.location.hostname.split('.')[0];
			}),
			switchMap(p => {
				if (!this.path) {
					throw new Error('Path is required');
				}
				return this.dataService.getEmployerIdFromPath(this.path);
			}),
			switchMap(id => this.dataService.getEmployer(id))
		).subscribe((e) => {
			this.employer = e;
			this.siteService.setEmployer(this.employer);
			this.employer.employerContent.contentCategories.forEach((category) => {
				this.content[category.contentCategoryID] = category.content.reduce((acc, contentItem) => ({ ...acc, [contentItem.contentDesc]: contentItem.textContent || contentItem.defaultTextContent || contentItem.fileContent || contentItem.defaultFileContent }), {});
			});
			this.topicCards = this.employer.employerCards.cardContainers.find(c => c.cardContainerID == 2)?.cards.map(c => new ContentCard(c));
			this.topicCardsToShow = this.topicCards?.slice(0, this.topicCardGroupsVisible * this.topicCardsPerGroup).map(c => new ContentCard({ ...c, added: true }));
		});
	}	

	ngAfterViewInit() {
		const timer = setInterval(() => {
			if (document.querySelector('.fade-in')) {
				clearInterval(timer);
				this.siteService.pageInitDone();
			}
		}, 500);
	}
	loadMore(): void {
		let nextTopicCardsToShow = this.topicCards.slice(this.topicCardGroupsVisible * this.topicCardsPerGroup, ++this.topicCardGroupsVisible * this.topicCardsPerGroup);
		nextTopicCardsToShow.forEach((c,i) => {
			setTimeout(() => { c.added = true; }, i * 250);
			this.topicCardsToShow.push(c);
		});
	}
	showLess(): void {
		this.topicCardsToShow = this.topicCardsToShow.slice(0, this.topicCardsPerGroup);
		this.topicCards.forEach((c,i) => {
			if(i >= this.topicCardsPerGroup) {
				c.added = false;
			}
		})
		this.topicCardGroupsVisible = 1;
	}

	layoutTopicCards(): void {
		this.topicCardsToShow = this.topicCards?.slice(0, this.topicCardGroupsVisible * this.topicCardsPerGroup).map(c => new ContentCard({ ...c, added: true }));
	}

}
