
export class Document {
	public employerID?: string;
	public docTitle: string;
	public docAuthor: string;
	public docUploadID: string;
	public docID: string;
	public docFile: string;
	public docTags: Array<string> = [];
	public lastUpdated?: Date;
	public fullTags: Array<DocTag> = [];
	public sortOrder: number;

	public adding: boolean = false;

	constructor(o?: any) {
		Object.assign(this, o);
	}
	get fileType() {
		if(!this.docFile) return '';
		return this.docFile.split('?')[0].split('.').pop().toUpperCase();
	}
}

export class DocTag {
	public tagID: string;
	public tagDesc: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}

}