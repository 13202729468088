import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarouselPageEvent } from 'primeng/carousel';
import { switchMap, tap } from 'rxjs/operators';
import { ContentCard } from 'src/app/models/content-card';
import { Employer } from 'src/app/models/employer';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

	public employer: Employer;
	public path: string;
	public content: any = {};
	public videoCards: Array<ContentCard> = [];

	public PAGE_SIZE = 1;
	public carouselIndex: number = 0;
	public showCarousel: boolean = true;

	public activeVideo: ContentCard;
	public vimeoId: string = '';
	public env = environment;

	constructor(private dataService: DataService, private route: ActivatedRoute, private siteService: SiteService, private changeDetector: ChangeDetectorRef) {
		this.route.parent?.parent?.data.subscribe(data => {
			this.path = data['path'] || window.location.hostname.split('.')[0];
			const vimeoId = data['vimeoId'];
			if (vimeoId) {
				this.vimeoId = vimeoId;
				this.playVimeoVideo();
			}
		});
	}

    isVimeo(url: string): boolean {
        return /(?:player\.vimeo\.com)/.test(url);
    }

	ngOnInit(): void {
		this.route.paramMap.pipe(
			tap(p => {
				this.path = p.get('path') || window.location.hostname.split('.')[0];
				const vimeoId = p.get('vimeoId');
				if (vimeoId) {
					this.vimeoId = vimeoId;
					this.playVimeoVideo();
				}
			}),
			switchMap(p => {
				if (!this.path) {
					throw new Error('Path is required');
				}
				return this.dataService.getEmployerIdFromPath(this.path);
			}),
			switchMap(id => this.dataService.getEmployer(id))
		).subscribe((e) => {
			this.employer = e;
			this.siteService.setEmployer(this.employer);
			this.employer.employerContent.contentCategories.forEach((category) => {
				this.content[category.contentCategoryID] = category.content.reduce((acc, contentItem) => ({ ...acc, [contentItem.contentDesc]: contentItem.textContent || contentItem.defaultTextContent }), {});
			});
			this.videoCards = this.employer.employerCards.cardContainers.find(c => c.cardContainerID == 1)?.cards.map(c => new ContentCard(c));
			this.changeDetector.detectChanges();
			this.siteService.pageInitDone();
		});
		this.siteService.onResize.subscribe(size => {
			switch(size) {
				case 's': this.PAGE_SIZE = 1; break;
				case 'm': this.PAGE_SIZE = 2; break;
				case 'l': this.PAGE_SIZE = 3; break;
				case 'xl': this.PAGE_SIZE = 4; break;
			}
			this.showCarousel = false; //trigger refresh of carousel
			setTimeout(() => { this.showCarousel = true; });
		});
		this.siteService.showDisclaimers = false;
	}	

	ngAfterViewInit() {
		
	}

	playVimeoVideo(): void {
		this.activeVideo = new ContentCard({
			title: 'One Morning',
			videoFile: `https://player.vimeo.com/video/${this.vimeoId}`,
			cardContent: ''
		});
		document.body.style.top = `-${window.scrollY}px`;
		document.body.style.position = 'fixed';
		document.body.style.paddingRight = '15px';
	}

	playVideo(card: ContentCard): void {
		this.activeVideo = card;
		document.body.style.top = `-${window.scrollY}px`;
		document.body.style.position = 'fixed';
		document.body.style.paddingRight = '15px';
	}

	closeVideo(): void {
		this.activeVideo = null;
		document.body.style.position = '';
		const scrollY = document.body.style.top;
		document.body.style.top = '';
		document.body.style.paddingRight = '0px';
		window.scrollTo(0, parseInt(scrollY || '0') * -1);
	}

	onPageChange(e: CarouselPageEvent) {
		this.carouselIndex = e.page;
	}
}
