import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DocTag, Document } from 'src/app/models/document';
import { HeaderButton } from 'src/app/models/header-button';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit{

	public allDocuments: Array<Document> = [];
	public documentsToShow: Array<Document> = [];
	public allTags: Array<DocTag> = [];
	public selectedTagIds: Array<string> = [];
	public documentsToReorder: Array<Document> = [];
	public showReorderDocumentsDialog: boolean = false;

	public showAddNewDocumentDialog: boolean = false;
	public showEditDocumentDialog: boolean = false;
	public showDeleteDocumentConfirmation: boolean = false;
	public editingDocument: Document;
	public deletingDocumentId: string;
	public isSuperAdmin: boolean = false;

	constructor(
		private dataService: DataService, 
		private siteService: SiteService,
		private adminService: AdminService
	) { 
		this.isSuperAdmin = this.adminService.isSuperAdmin;
	}

	ngOnInit() {
		this.siteService.pageTitle = 'Library';
		if (this.isSuperAdmin) {
		this.siteService.headerButtons = [
			new HeaderButton({ text: 'Reorder', class: 'neutral', callback: () => this.reorderDocuments() }),
			new HeaderButton({ text: 'Add Document', class: 'neutral', callback: () => this.addDocument() }),
		];
	}

		forkJoin({
			documents: this.dataService.getDocuments(),
			tags: this.dataService.getTags()
		}).subscribe(({documents, tags }) => {
			this.allDocuments = documents.map((doc:any) => new Document(doc)).sort((a, b) => a.sortOrder - b.sortOrder);
			this.allTags = tags;
			this.allDocuments.forEach(d => d.fullTags = d.docTags.map(t => this.allTags.find(tag => tag.tagID == t)));
			this.documentsToShow = this.allDocuments;
		});
	}

	addDocument() {
		this.editingDocument = new Document({ docID: crypto.randomUUID(), docTags: [] });
		this.showAddNewDocumentDialog = true;
	}
	closeAddDocumentDialog() {
		this.showAddNewDocumentDialog = false;
	}
	closeEditDocumentDialog() {
		this.showEditDocumentDialog = false;
	}	
	editDocument(document: Document) {
		this.editingDocument = document;
		this.showEditDocumentDialog = true;
	}
	onDocumentSaved(document: Document) {
		document.fullTags = document.docTags.map(t => this.allTags.find(tag => tag.tagID == t));
		if(this.allDocuments.find(d => d.docID === document.docID)) {
			this.allDocuments = this.allDocuments.map(d => d.docID === document.docID ? document : d);
		} else {
			this.allDocuments.push(document);
		}
		this.documentsToShow = this.allDocuments;
		this.closeEditDocumentDialog();
		this.closeAddDocumentDialog();
	}

	deleteDocument(document: Document) {
		this.deletingDocumentId = document.docID;
		this.showDeleteDocumentConfirmation = true;
	}
	filterDocuments() {
		this.documentsToShow = [ ...this.allDocuments.filter(d => this.selectedTagIds.length == 0 || d.docTags.some(t => this.selectedTagIds.includes(t))) ];
	}
	onConfirmClick({ action, data }: {action: string, data: any}) {
		switch(action) {
			case 'Cancel':
				this.showDeleteDocumentConfirmation = false;
				break;
			case 'Delete Document':
				this.dataService.deleteDocument(data).subscribe(() => {
					this.siteService.setMessage('Document deleted successfully', 'success');
					this.showDeleteDocumentConfirmation = false;
					this.allDocuments = this.allDocuments.filter(d => d.docID != data);
					this.filterDocuments();
				});
				break;

		}
	}

	tagDeleted(tagID: string) {
		this.allDocuments.forEach(d => {
			d.docTags = d.docTags.filter(t => t !== tagID)
			d.fullTags = d.fullTags.filter(tag => tag.tagID !== tagID);
		});

		this.filterDocuments();
	}
	tagEdited(tag: DocTag) {
		this.allTags = this.allTags.map(t => t.tagID === tag.tagID ? tag : t);
		this.allDocuments.forEach(d => {
			d.fullTags = d.docTags.map(t => this.allTags.find(tag => tag.tagID == t));
		});
	}
	tagAdded(tag: DocTag) {
		this.allTags.push(tag);
	}

	reorderDocuments() {
		this.documentsToReorder = [ ...this.allDocuments ];
		this.showReorderDocumentsDialog = true;
	}
	closeReorderDocumentsDialog() {
		this.showReorderDocumentsDialog = false;
		this.documentsToReorder = [];
	}
	saveDocumentOrder() {
		let docs = this.documentsToReorder.map((d, i) => ({ docID: d.docID, sortOrder: i }));
		this.dataService.sortDocuments(docs).subscribe(() => {
			this.allDocuments = this.documentsToReorder;
			this.filterDocuments();
			this.closeReorderDocumentsDialog();
		});
	}
}
